<template>
  <div>
    <Card>
      <template #header>Taşınır Kayıtları</template>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <div class="d-flex align-items-center">
          <b-form-group>
            <b-button variant="primary" @click="addFileModal = true"
              >Taşınır</b-button
            >
          </b-form-group>
        </div>
      </div>

      <b-modal v-model="addFileModal" id="note-modal">
        <template #modal-header>
          <h4>Yeni Taşınır Bilgileri</h4>
        </template>
        <div class="form-group">
          <div class="row">
            <div class="col-6">
              <label for="">Demirbaş No</label>
              <input type="text" class="form-control" v-model="fixture_no" />
            </div>
            <div class="col-6">
              <label for="">Taşınır Sıra No</label>
              <input
                type="text"
                class="form-control"
                v-model="moveable_order_no"
              />
            </div>
            <div class="col-12 mt-5">
              <label for="">Taşınır Adı</label>
              <input type="text" class="form-control" v-model="name" />
            </div>
            <div class="col-4 mt-5">
              <label for="">Taşınır Marka</label>
              <input type="text" class="form-control" v-model="brand" />
            </div>
            <div class="col-4 mt-5">
              <label for="">Taşınır Model</label>
              <input type="text" class="form-control" v-model="model" />
            </div>
            <div class="col-4 mt-5">
              <label for="">Taşınır Seri</label>
              <input type="text" class="form-control" v-model="serial" />
            </div>
            <div class="col-12 mt-5">
              <label for="">Kapasite ve Teknik Özellikler</label>
              <input type="text" class="form-control" v-model="special_text" />
            </div>
            <div class="col-6 mt-5">
              <label for="">Model Yılı</label>
              <input type="text" class="form-control" v-model="model_year" />
            </div>
            <div class="col-6 mt-5">
              <label for="">Adet</label>
              <input type="text" class="form-control" v-model="quantity" />
            </div>
          </div>
        </div>
        <template #modal-footer>
          <div class="d-flex justify-content-end w-100">
            <b-button variant="light" @click="resetModal">
              Vazgeç
            </b-button>
            <b-button variant="success" class="ml-4" @click="save">
              Kaydet
            </b-button>
          </div>
        </template>
      </b-modal>
    </Card>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="Taşınır Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-content-end">
          <button class="btn p-0" @click="updateMoveable(item)">
            <i class="flaticon-eye text-dark text-md"></i>
          </button>
          <button class="btn p-0" @click="openDeleteHandler(item)">
            <i class="flaticon2-trash text-dark text-md"></i>
          </button>
          <button class="btn p-0">
            <i class="flaticon2-files-and-folders text-dark text-md"></i>
          </button>
        </div>
      </template>
    </List>
    <DeleteOperation
      :url="deleteOperationUrl"
      :params="deleteIds"
      @updateList="getList"
      modalid="deleteMoveable"
    />
    <!-- Create and Actions Popups -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "systemUsers",
  data() {
    return {
      fixture_no: "",
      moveable_order_no: "",
      name: "",
      brand: "",
      model: "",
      serial: "",
      special_text: "",
      model_year: "",
      quantity: "",

      addFileModal: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "Demirbas No", sortaable: true, value: "fixture_no" },
        { text: "Taşınır Adı", value: "name" },
        { text: "Marka", value: "brand" },
        { text: "Model", value: "model" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",

      updateModel: false,
      moveable_id: "",
    };
  },

  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.items = [];
      this.totalItems = 0;

      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `property/moveable?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&user_token=${this.myUser}&property_id=${
        this.$route.params.id
      }`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$dbFunctions
        .get(query)
        .then((res) => {
          this.items = res.response.data;
          this.totalItems = res.response.data.count;

          this.pagination = {
            current_page: res.response.data.page,
            total_pages: res.response.data.pageCount,
            total_items: res.response.data.count,
          };
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      let data = {
        fixture_no: this.fixture_no,
        moveable_order_no: this.moveable_order_no,
        name: this.name,
        brand: this.brand,
        model: this.model,
        serial: this.serial,
        special_text: this.special_text,
        model_year: this.model_year,
        quantity: this.quantity,
        property_id: this.$route.params.id,
        user_token: this.myUser,
      };
      if(!this.updateModel){
        this.$ApiService
        .post("property/moveable", data)
        .then((res) => {
          this.$generateNotification(this, "success", "Taşınır Eklenmiştir.");
          this.getList();
          this.addFileModal = false;
        })
        .catch((err) => {
          this.$generateNotification(this, "error", "Taşınır Eklenemedi.");
        });
      }else{
        data["moveable_id"]= this.moveable_id
        const formData = new FormData()
        for(const key in data){
          formData.append(key,data[key])
        }
        this.$ApiService
        .put("property/moveable", formData)
        .then((res) => {
          this.$generateNotification(this, "success", "Taşınır Başarıyla Güncellendi.");
          this.getList();
          this.resetModal()
          this.updateModel = false
        })
        .catch((err) => {
          this.$generateNotification(this, "error", "Taşınır Güncellenemedi.");
        });
      }
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    updateMoveable(item) {
      this.moveable_id = item._id.$oid;
      this.fixture_no = item.fixture_no;
      this.moveable_order_no = item.moveable_order_no;
      this.name = item.name;
      this.brand = item.brand;
      this.model = item.model;
      this.serial = item.serial;
      this.special_text = item.special_text;
      this.model_year = item.model_year;
      this.quantity = item.quantity;
      this.updateModel = true;
      this.addFileModal = true;
    },
    resetModal(){
      this.moveable_id = "";
      this.fixture_no = "";
      this.moveable_order_no = "";
      this.name = "";
      this.brand = "";
      this.model = "";
      this.serial = "";
      this.special_text = "";
      this.model_year = "";
      this.quantity = "";
      this.addFileModal = false
    },
    
    openDeleteHandler(item) {
      this.deleteOperationUrl = `property/moveable`;
      this.deleteIds = `?user_token=${this.myUser}&moveable_id=${item._id.$oid}&property_id=${this.$route.params.id}`;
      this.$bvModal.show('modal-deleteOperationdeleteMoveable');
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>
